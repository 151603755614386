import styled from "styled-components";

export const A = styled.a`
  font-family: "Josefin Sans", "Open Sans", "Helvetica Neue", Helvetica,
    sans-serif;
  color: #9d751c;
  &:hover {
    color: #9d751c;
    cursor: pointer;
  }
`;
