import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { Message } from "semantic-ui-react";

import { Text } from "../components/elements/text";
import { A } from "./elements/anchor";

type SubscriptionInfoProps = {
  product: {
    id: number;
    name: string;
    url: string;
    isSubscription: boolean;
  };
};

export const SubscriptionInfo: React.FC<SubscriptionInfoProps> = ({
  product,
}) => {
  const [data, setData] = useState<{ subscriptions: any[] }>({
    subscriptions: [],
  });

  // Reqeust own subscriptions
  useEffect(() => {
    async function requestData() {
      try {
        const token = await localStorage.getItem("token");
        const result = await axios(
          process.env.REACT_APP_BACKEND_URL + "/subscriptions/own",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setData({
          subscriptions: result.data ? result.data : [],
        });
      } catch (e) {
        console.log("could not request subscriptions");
      }
    }

    requestData();
  }, []);

  // Filter subscriptions per product
  const subscriptions = data.subscriptions.filter((s) => {
    return s.productId === product.id;
  });

  enum SubscriptionState {
    "NO_SUBSCRIPTION",
    "FUTURE_SUBSCRIPTION",
    "EXPIRED_SUBSCRIPTION",
    "ACTIVE_SUBSCRIPTION",
  }

  type SubscriptionInfo = {
    subscription: any;
    state: SubscriptionState;
  };

  // Check if a subscriptions is active
  const subscriptionInfo = subscriptions.reduce<SubscriptionInfo>(
    (result, subscription) => {
      if (subscription.startDate && subscription.endDate) {
        const isBetween = moment().isBetween(
          subscription.startDate,
          subscription.endDate,
          "day",
          "[]"
        );
        const isAfter = moment().isAfter(subscription.endDate, "day");

        // Beginning
        if (result.state === SubscriptionState.NO_SUBSCRIPTION) {
          return isBetween
            ? {
                subscription,
                state: SubscriptionState.ACTIVE_SUBSCRIPTION,
              }
            : isAfter
            ? {
                subscription,
                state: SubscriptionState.EXPIRED_SUBSCRIPTION,
              }
            : { subscription, state: SubscriptionState.FUTURE_SUBSCRIPTION };
        }
        return isBetween
          ? {
              subscription,
              state: SubscriptionState.ACTIVE_SUBSCRIPTION,
            }
          : result;
      }

      if (!subscription.endDate) {
        // Open end subscription, so its active
        return {
          subscription,
          state: SubscriptionState.ACTIVE_SUBSCRIPTION,
        };
      }

      return result;
    },
    {
      subscription: null,
      state: SubscriptionState.NO_SUBSCRIPTION,
    }
  );

  let message;
  switch (subscriptionInfo.state) {
    case SubscriptionState.NO_SUBSCRIPTION: {
      message = {
        header: "Noch kein Abo",
        text: (
          <Text>
            Hallo! Du möchtest gerne diesen Online Yoga Kurse von YogaWelten
            ansehen, dafür bitte ich Dich beim{" "}
            <A target="_blank" rel="noopener noreferrer" href={product.url}>
              Webshop
            </A>{" "}
            das entsprechende Online Video Abonnement zu kaufen. Nach Eingang
            der Zahlung wird Dir das Angebot freigeschalten. Bei weiteren Fragen
            wende Dich bitte an{" "}
            <A href="mailto:info@yogawelten.ch">info@YogaWelten.ch</A>.
            <br />
            Viel Spass und Danke für Dein Interesse!
          </Text>
        ),
      };
      break;
    }
    case SubscriptionState.EXPIRED_SUBSCRIPTION: {
      if (product.isSubscription) {
        message = {
          header: `Hallo! Dein Abonnement ist seit ${moment(
            subscriptionInfo.subscription.endDate
          ).format("DD.MM.YYYY")} abgelaufen`,
          text: (
            <Text>
              Bitte kaufe ein neues Abo beim{" "}
              <A target="_blank" rel="noopener noreferrer" href={product.url}>
                Webshop
              </A>{" "}
              um das entsprechende Online Video Abonnement zu kaufen. Nach
              Eingang der Zahlung wird Dir das Angebot aufgeschalten und Du
              kannst weiterhin die Online Videos in dieser Kategorie, so oft Du
              möchtest ansehen. Bei weiteren Fragen wende Dich bitte an{" "}
              <A href="info@yogawelten.ch">info@YogaWelten.ch</A>. Viel Spass
              und Danke für Dein Interesse!
            </Text>
          ),
        };
        break;
      } else {
        // No message if abo is active
        message = null;
        break;
      }
    }
    case SubscriptionState.FUTURE_SUBSCRIPTION: {
      message = {
        header: "Abo wird aufgeschalten",
        text: (
          <Text>
            Das Abo läuft ab dem{" "}
            {moment(subscriptionInfo.subscription.startDate).format(
              "DD.MM.YYYY"
            )}
            .
          </Text>
        ),
      };
      break;
    }
    default: {
      // No message if abo is active
      message = null;
      break;
    }
  }

  return message === null ? null : (
    <>
      <Message
        color="yellow"
        style={{ boxShadow: "none" }}
        // info={subscriptionInfo.state !== SubscriptionState.ACTIVE_SUBSCRIPTION}
      >
        <Message.Header /*style={{ color: "rgba(54, 54, 54, 1)" }}*/>
          {message.header}
        </Message.Header>
        {message.text}
      </Message>
    </>
  );
};
