import React, { useState, useEffect } from "react";
import useGlobalState from "../state/useGlobalState";
import { Redirect } from "react-router";
import ReactPlayer from "react-player";
import moment from "moment";
import axios from "axios";
import { Dropdown, Grid, Icon, Form } from "semantic-ui-react";
import { Heading } from "../components/elements/heading";
import { Button } from "../components/elements/button";
import { Text } from "../components/elements/text";

import "../player.css";
import { SubscriptionInfo } from "../components/SubscriptionInfo";
import { Link } from "../components/elements/link";

export const VideosPage: React.FC<{}> = (props) => {
  const api = useGlobalState();
  const [data, setData] = useState<{ videos: any[] }>({ videos: [] });
  const [product, setProducts] = useState<{ products: any[] }>({
    products: [],
  });
  const [filter, setFilter] = useState(-1);
  const [order, setOrder] = useState("date");
  const [page, setPage] = useState(0);

  // Reqeust videos
  useEffect(() => {
    async function requestData() {
      try {
        const token = await localStorage.getItem("token");
        const result = await axios(
          process.env.REACT_APP_BACKEND_URL + "/videos/subscriptions",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setData({
          videos: result.data ? result.data : [],
        });
        /*
        if (result.data && result.data[0]) {
          setFilter(result.data[0].product.id);
        }
        */
      } catch (e) {
        console.log("could not request videos because of" + e.message);
      }
    }

    requestData();
  }, []);

  // Request products
  useEffect(() => {
    async function requestData() {
      try {
        const token = await localStorage.getItem("token");
        const result = await axios(
          process.env.REACT_APP_BACKEND_URL + "/products",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setProducts({
          products: result.data ? result.data : [],
        });
      } catch (e) {
        console.log("could not request products");
      }
    }

    requestData();
  }, []);

  if (!api.state.loggedIn) {
    return <Redirect to="/login" />;
  }

  // Order videos
  const videos = data.videos
    .filter((v) => {
      if (filter === -1) {
        // Alle Videos
        return true;
      }
      return v.product.id === filter;
    })
    .sort((v1, v2) => {
      if (v1[order] > v2[order]) {
        return order === "date" ? -1 : 1;
        // return 1;
      }

      if (v1[order] < v2[order]) {
        return order === "date" ? 1 : -1;
        // return -1
      }

      return 0;
    });

  const selectedProduct = product.products.reduce((prev, curr) => {
    if (curr.id === filter) {
      return curr;
    }
    return prev;
  }, null);

  const orderOptions = [
    {
      key: "date",
      value: "date",
      text: "Datum",
      icon: "sort numeric descending",
    },
    {
      key: "name",
      value: "name",
      text: "Titel",
      icon: "sort alphabet ascending",
    },
    {
      key: "topic",
      value: "description",
      text: "Thema",
      icon: "sort alphabet ascending",
    },
  ];

  const productOptions = [
    {
      key: -1,
      value: -1,
      text: "Alle deine Kurse",
    },
    ...product.products.map((product) => {
      return {
        key: product.id,
        value: product.id,
        text: product.name,
      };
    }),
  ];

  return (
    <>
      <Grid centered columns={2}>
        <Grid.Column textAlign="left" width="12">
          <Text>
            Es gibt ein <Link to="/introduction">Einführungsvideo</Link> zu den
            Online Kundalini Yoga Kursen. Dies erklärt den Ablauf der Kurse, auf
            was zu achten ist und was Kundalini Yoga ist. Bitte schaue das Video
            vor dem Start der Online Kurse an.
          </Text>
        </Grid.Column>
        <Grid.Column textAlign="right" width="4">
          <Button onClick={api.logout}>Logout</Button>
        </Grid.Column>
      </Grid>
      <br />
      {selectedProduct ? <SubscriptionInfo product={selectedProduct} /> : null}
      <Heading style={{ marginBottom: 0 }}>
        {selectedProduct ? selectedProduct.name : "Deine YOGAWELTEN KURSE"}
      </Heading>
      {selectedProduct ? <Text>{selectedProduct.description}</Text> : null}
      <br />
      <br />
      <Form>
        <Form.Field>
          <label style={{ fontSize: "16px" }}>Kategorie</label>
          <Dropdown
            placeholder="Kategorie auswählen"
            value={filter}
            fluid
            selection
            onChange={(e, data) => {
              setFilter(parseInt(data.value as string, 10));
              setPage(0);
            }}
            options={productOptions}
          />
        </Form.Field>

        <Form.Field>
          <label style={{ fontSize: "16px" }}>Sortieren nach</label>
          <Dropdown
            value={order}
            fluid
            selection
            onChange={(e, data) => {
              setOrder(data.value as string);
              setPage(0);
            }}
            options={orderOptions}
          />
        </Form.Field>
      </Form>
      <br />
      <br />
      {videos && videos.length > 0 && page > -1 && (
        <Grid columns={2}>
          {videos.slice(page * 10, page * 10 + 10).map((video) => {
            return (
              <Grid.Column key={video.id} verticalAlign="top">
                <Heading style={{ marginBottom: 0 }}>{video.name}</Heading>
                <Text>{video.description}</Text>

                <ReactPlayer
                  url={video.videoUrl}
                  width="100%"
                  height="270px"
                  controls={true}
                  config={{
                    vimeo: {
                      playerOptions: {
                        title: false,
                      },
                    },
                  }}
                />
                <Grid>
                  <Grid.Column textAlign="right">
                    <Text style={{ marginTop: "5px" }}>
                      <Icon name="calendar alternate" />{" "}
                      {moment(video.date).format("DD.MM.YYYY")}
                    </Text>
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            );
          })}
          <Grid.Column>
            <Button hidden={page == 0} onClick={() => setPage(page - 1)}>
              Vorherige
            </Button>
          </Grid.Column>

          <Grid.Column textAlign="right">
            <Button
              hidden={videos && Math.floor(videos.length / 10) <= page}
              onClick={() => setPage(page + 1)}
            >
              Nächste
            </Button>
          </Grid.Column>
        </Grid>
      )}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
};
