import React, { useState, SyntheticEvent } from "react";
import axios from "axios";
import useGlobalState from "../state/useGlobalState";
import { Redirect } from "react-router-dom";
import { Heading } from "../components/elements/heading";
import { Grid, Form, Message } from "semantic-ui-react";
import { Button } from "../components/elements/button";
import { A } from "../components/elements/anchor";

export const LoginPage: React.FC<{}> = (props) => {
  const api = useGlobalState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [acceptAGB, setAcceptAGB] = useState(false);
  const [acceptPolicy, setAcceptPolicy] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  function validateForm() {
    return email.length > 0 && password.length > 0 && acceptAGB && acceptPolicy;
  }

  function handleSubmit(event: SyntheticEvent<{}>) {
    event.preventDefault();
    setErrorMessage("");

    if (validateForm()) {
      // Login
      axios
        .post(process.env.REACT_APP_BACKEND_URL + "/auth/login", {
          username: email,
          password,
        })
        .then(({ data }) => {
          if (data && data.access_token) {
            console.log("Log in success");
            localStorage.setItem("token", data.access_token);
            api.login({ token: data.access_token });
          }
        })
        .catch((e) => {
          setErrorMessage(
            "Anmeldung fehlgeschlagen. Bitte überprüfe deine E-Mail-Adresse und dein Passwort."
          );

          console.log("Error " + e.message);
        });
    } else {
      if (!acceptAGB || !acceptPolicy) {
        setErrorMessage(
          "Hallo! Die AGBs und Datenschutzerklärung müssen akzeptiert werden."
        );
      } else {
        setErrorMessage(
          "Bitte füllen Sie die E-Mail-Adresse und das Passwort aus"
        );
      }
    }
  }

  if (api.state.loggedIn) {
    return <Redirect to="/introduction" />;
  }

  return (
    <>
      <Grid
        textAlign="center"
        // style={{ height: "106px" }} // 10% of 1058px
        style={{ marginBottom: "75px" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 900 }}>
          <Heading>Zugang Videokurse</Heading>
          <p style={{ fontSize: "18px", lineHeight: 1.8 }}>
            Hallo! Du möchtest gerne die Online Yoga Kurse von YogaWelten
            ansehen,
            <br />
            dafür bitte ich Dich hier um Anmeldung mit Deiner Email Adresse und
            Deinem Passwort, welches Dir
            <br />
            per Email zugesendet wurde. Bei weiteren Fragen oder für ein neues
            Passwort wende Dich bitte an
            <br />
            <A href="mailto:info@yogawelten.ch">info@YogaWelten.ch</A>. Viel
            Spass und Danke für Dein Interesse!
          </p>
        </Grid.Column>
      </Grid>

      <Grid
        textAlign="center"
        // style={{ height: "846px" }} // 80% of 1058px, kann noch erhöht werden auf 90%
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Heading>Login YogaWelten Kurse</Heading>
          <Form size="large" onSubmit={handleSubmit}>
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              name="userName"
              placeholder="E-Mail-Adresse"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />

            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Passwort"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />

            <Form.Checkbox
              name="acceptAGB"
              onChange={() => setAcceptAGB(!acceptAGB)}
              label={
                <label style={{ fontSize: "1.1em" }}>
                  Ich habe die{" "}
                  <A href="/AGBs_Waiver_YogaWelten_Kurse.pdf">
                    AGBs & Verzicht auf die Haftung
                  </A>{" "}
                  gelesen. Ich verstehe deren Inhalt vollständig und stimme den
                  genannten Bedingungen und AGB’s zu.
                </label>
              }
            />

            <Form.Checkbox
              name="acceptPolicy"
              onChange={() => setAcceptPolicy(!acceptPolicy)}
              label={
                <label style={{ fontSize: "1.1em" }}>
                  Ja, ich habe die{" "}
                  <A href="/Datenschutzerklaerung_YogaWelten.pdf">
                    Datenschutzerklärung
                  </A>{" "}
                  zur Kenntnis genommen und bin damit einverstanden, dass die
                  von mir angegebenen Daten elektronisch erhoben und gespeichert
                  werden. Meine Daten werden dabei nur streng zweckgebunden zur
                  Bearbeitung und Beantwortung meiner Anfrage benutzt.
                </label>
              }
            />

            {errorMessage && (
              <Message negative size="mini">
                {errorMessage}
              </Message>
            )}

            <Button type="submit">Anmelden</Button>
          </Form>
        </Grid.Column>
      </Grid>
    </>
  );
};
