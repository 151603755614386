import styled from "styled-components";
import { Link as RLink } from "react-router-dom";

export const Link = styled(RLink)`
  font-family: "Josefin Sans", "Open Sans", "Helvetica Neue", Helvetica,
    sans-serif;
  color: #9d751c;
  &:hover {
    color: #9d751c;
    cursor: pointer;
  }
`;
