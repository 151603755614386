import React, { createContext, useReducer } from "react";

/* Actions */
const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";

type Actions = {
  type: typeof LOGIN | typeof LOGOUT;
  payload: any;
};

const initialState = {
  loggedIn: localStorage.getItem("token") ? true : false,
  token: localStorage.getItem("token")
};

export type GlobalState = typeof initialState;

export const GlobalStateContext = createContext({} as any);

const globalStateReducer = (
  state: GlobalState,
  action: Actions
): GlobalState => {
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        loggedIn: true
      };
    }
    case LOGOUT: {
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        loggedIn: false
      };
    }
    default: {
      return state;
    }
  }
};

export const GlobalStateProvider: React.FC<{}> = ({ children }) => {
  const [state, dispatch] = useReducer(globalStateReducer, initialState);

  return (
    <GlobalStateContext.Provider value={[state, dispatch]}>
      {children}
    </GlobalStateContext.Provider>
  );
};
