import React from "react";

export const Layout: React.FC<{}> = props => {
  return (
    <div
      style={{
        width: "1000px",
        height: "1058px",
        position: "relative",
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor: "rgba(243,241,239,1)",
        padding: "49px 20px 0 10px"
      }}
    >
      {props.children}
    </div>
  );
};
