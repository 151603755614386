import styled from "styled-components";

export const Text = styled.p`
  font-family: "Josefin Sans", "Open Sans", "Helvetica Neue", Helvetica,
    sans-serif;
  font-size: 18px;
  color: rgba(54, 54, 54, 1);
  line-height: 1.8;
  margin-bottom: 5px;
`;
