import React, { useState } from "react";
import { Form, Grid } from "semantic-ui-react";
import { Heading } from "../components/elements/heading";
import { Text } from "../components/elements/text";
import { Redirect } from "react-router";
import ReactPlayer from "react-player";
import { A } from "../components/elements/anchor";

export const IntroductionPage: React.FC<{}> = props => {
  const [acceptIntro, setAcceptIntro] = useState(false);

  if (acceptIntro) {
    return <Redirect to="/videos" />;
  }

  return (
    <>
      <Grid textAlign="center" verticalAlign="middle">
        <Grid.Column style={{ maxWidth: 1200 }}>
          <Heading>Einführungsvideo</Heading>
          <Text
            style={{
              marginBottom: "30px"
            }}
          >
            Hallo! Dieses Video erklärt Dir was Kundalini Yoga ist,
            <br />
            woher es kommt und was die Bedeutung ist. Zudem wird der Ablauf
            <br />
            der Übungen und auf was zu achten ist erklärt. Bitte schaue Dir das
            Video an und wenn
            <br />
            Du Fragen haben solltest melde Dich einfach bei mir{" "}
            <A href="info@yogawelten.ch">info@YogaWelten.ch</A>.<br />
            Viel Spass und einen guten Start mit den Online Kursen!
          </Text>

          <ReactPlayer
            url="https://vimeo.com/398272380/b2e6b3be6a"
            width="100%"
            height="270px"
            controls={true}
            config={{
              vimeo: {
                playerOptions: {
                  title: false
                }
              }
            }}
          />

          <br />
          <br />

          <Form>
            <Form.Checkbox
              name="acceptIntro"
              onChange={() => setAcceptIntro(!acceptIntro)}
              label={
                <label
                  style={{
                    fontSize: "1.1em",
                    textAlign: "center",
                    verticalAlign: "middle",
                    maxWidth: "450px"
                  }}
                >
                  Ich bestätige hiermit das ich das Einführungsvideo zu den
                  Online Kundalini Yoga Kursen angesehen und verstanden habe.
                </label>
              }
            />
          </Form>

          <br />
          <br />
        </Grid.Column>
      </Grid>
    </>
  );
};
