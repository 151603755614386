import { useContext } from "react";
import { GlobalStateContext, GlobalState } from "./GlobalStateProvider";

const useGlobalState = () => {
  const [state, dispatch] = useContext(GlobalStateContext);

  const login = ({ token }: { token: string }) => {
    dispatch({ type: "LOGIN", payload: { token } });
  };

  const logout = () => {
    dispatch({ type: "LOGOUT", payload: {} });
  };

  return {
    login,
    logout,
    state: state as GlobalState
  };
};

export default useGlobalState;
