import styled from "styled-components";

export const Button = styled.button`
  width: 160px;
  height: 40px;
  border: none;
  font-family: "Josefin Sans", "Open Sans", "Helvetica Neue", Helvetica,
    sans-serif;
  font-size: 16px;
  background-color: rgba(255, 217, 133, 1);
  padding: 5px 10px;
  text-decoration: none;
  color: rgba(54, 54, 54, 1);
  text-align: center;
  text-transform: uppercase;
  &:hover {
    color: rgba(179, 133, 32, 1);
    cursor: pointer;
  }
  &:disabled {
    background-color: rgba(243, 241, 239, 1);
    border: 1px solid rgba(54, 54, 54, 1);
    color: rgba(54, 54, 54, 1);
    cursor: not-allowed;
  }
`;
