import styled from "styled-components";

export const Heading = styled.h3`
  font-family: "Josefin Sans", "Open Sans", "Helvetica Neue", Helvetica,
    sans-serif;
  font-size: 24px;
  font-weight: normal;
  color: rgba(54, 54, 54, 1);
  text-transform: uppercase;
  line-height: 1.8;
  margin-bottom: 32px;
`;

/* Margin Bottom comes from an empty paragraph after the heading (manually inserted) */
