import React from "react";
import "./App.css";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { VideosPage } from "./pages/Videos";
import { LoginPage } from "./pages/Login";
import { Layout } from "./components/Layout";
import { GlobalStateProvider } from "./state/GlobalStateProvider";

import { createGlobalStyle } from "styled-components";
import { IntroductionPage } from "./pages/Introduction";
const GlobalStyles = createGlobalStyle`
 
  body {
    @import url('https://fonts.googleapis.com/css?family=Josefin+Sans&display=swap');
    font-family: 'Josefin Sans', 'Open Sans', 'Helvetica Neue', Helvetica, sans-serif;
    font-size: "18px";
  }
`;

function App() {
  return (
    <Router>
      <GlobalStateProvider>
        <Layout>
          <GlobalStyles />
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/introduction">
              <IntroductionPage />
            </Route>
            <Route path="/videos">
              <VideosPage />
            </Route>
            <Route path="/" exact>
              <Redirect to="/videos" />
            </Route>
          </Switch>
        </Layout>
      </GlobalStateProvider>
    </Router>
  );
}

export default App;
